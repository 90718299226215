import React from 'react'
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from 'configs/AppConfig'

export const publicRoutes = [
    {
        key: 'login',
        path: `${AUTH_PREFIX_PATH}/login`,
        component: React.lazy(() => import('views/auth-views/authentication/login')),
    },
    {
        key: 'forgot-password',
        path: `${AUTH_PREFIX_PATH}/forgot-password`,
        component: React.lazy(() => import('views/auth-views/authentication/forgot-password')),
    }
]

export const protectedRoutes = [
    {
        key: 'dashboard.default',
        path: `${APP_PREFIX_PATH}/dashboards/default`,
        component: React.lazy(() => import('views/app-views/dashboards/default')),
    },
    {
        key: 'cadastros.equipamentos',
        path: `${APP_PREFIX_PATH}/cadastros/equipamentos`,
        component: React.lazy(() => import('views/app-views/cadastros/equipamentos')),
    },
    {
        key: 'cadastros.equipamentos.new',
        path: `${APP_PREFIX_PATH}/cadastros/equipamentos/new`,
        component: React.lazy(() => import('views/app-views/cadastros/equipamentos/new')),
    },
    {
        key: 'cadastros.equipamentos.edit',
        path: `${APP_PREFIX_PATH}/cadastros/equipamentos/edit/:id`,
        component: React.lazy(() => import('views/app-views/cadastros/equipamentos/edit')),
    },
    {
        key: 'cadastros.contratos',
        path: `${APP_PREFIX_PATH}/cadastros/contratos`,
        component: React.lazy(() => import('views/app-views/cadastros/contratos')),
    },
    {
        key: 'reports.falhas',
        path: `${APP_PREFIX_PATH}/reports/falhas`,
        component: React.lazy(() => import('views/app-views/reports/falhas')),
    },
    {
        key: 'reports.recargas',
        path: `${APP_PREFIX_PATH}/reports/recargas`,
        component: React.lazy(() => import('views/app-views/reports/recargas')),
    }
]